h1, h2, h3 {
  -webkit-font-smoothing: antialiased;
}

.page-inner {
  @apply w-full md:max-w-screen-xl;

  &-full-wrap {
    @apply w-full;
  }

  &.footer-desc {
    max-width: 1440px;

    background-image: url("./assets/footer-gradients-bg.png");
    background-repeat: repeat-x;
    background-position: bottom;

    @screen sm {
      background-repeat: no-repeat;
      background-size: 100%;
      background-position-y: bottom;
      background-image: url("./assets/light_spots_full_bg.png");
    }
  }

  &.footer-nav {
    @apply pt-4 flex justify-center sm:pt-16;

    max-width: unset !important;
    width: 100%;

    background: rgb(2, 0, 36);
    background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(0, 0, 0, 1) 0%, rgba(0, 212, 255, 0) 100%);
  }
}

.app-head-showcase {
  @apply pt-14;
  -webkit-font-smoothing: antialiased;

  > .inner {
    @apply pt-0 sm:pt-16;
  }

  .text-1 {
    @apply pt-8 px-4
    sm:pt-16 sm:items-center;

    line-height: 3rem;

    @screen sm {
      line-height: 5.75rem;
    }
  }

  .image-2 {
    background-image: url("./assets/header_bg.png");
    background-size: contain;
    top: 160px;
    left: 50%;
    transform: translateX(-50%);
    width: 600px;
    height: 570px;
    background-position-y: -110px;
    background-position-x: -12px;

    @screen sm {
      top: 170px;
    }
  }

  .cards-3 {
    @apply absolute;

    width: 86vw;
    max-width: 500px;

    top: 160px;
    left: 50%;
    transform: translateX(-50%);

    .glass-card {
      &.is-card-todo {
      }

      &.is-card-profile, &.is-card-book {
        width: 260px;

        .glass-card-inner {
          @apply flex text-sm;
          min-height: 110px;
        }

        .avatar {
          &-img {
            width: 60px;
            height: 60px;
            border-radius: 100%;
            overflow: hidden;
            background-image: url("./assets/avatar.png");
            background-size: contain;
            text-indent: -999px;
          }
        }

        @screen sm {
          flex-basis: 220px;
        }
      }

      &.is-card-book {
        flex: 1;

        .glass-card-inner {
          min-height: 110px;
        }

        .avatar {
          &-img {
            width: 60px;
            height: 84px;
            border-radius: unset;
            overflow: hidden;
            background-image: url("./assets/cover.png");
            background-size: contain;
            text-indent: -999px;
          }
        }
      }
    }

    > .r2 {
      @apply flex flex-row sm:space-x-3;

      &.is-single {
        @apply justify-end;

        .glass-card {
          flex: unset;
        }
      }
    }

    > .r3 {

    }

    @screen sm {
      width: 500px;
      top: 300px;
    }
  }

  .actions-4 {
    @apply flex px-4
    w-full flex-col justify-center pb-5
    sm:flex-row sm:space-x-4;

    button {
      @apply relative w-full mb-2 -translate-y-6
      sm:w-auto sm:translate-y-0;

      &:last-child {
        @apply top-2 sm:top-0;
      }
    }
  }

  &.active-ref {
    &-profile {
      .is-card-profile {
        transition: 300ms;
        transform: scale(1.05);
      }
    }

    &-book {
      .is-card-book {
        transition: 300ms;
        transform: scale(1.05);
      }
    }
  }
}

.outliner-list {
  &-demo {

  }

  &-item {
    @apply relative text-sm;

    padding-left: 18px;

    &:before {
      content: "•";
      position: absolute;
      font-size: 26px;
      font-weight: bold;
      left: 0;
      top: 0;
      opacity: .4;
    }

    > .content {
      @apply flex items-center text-logseq-50/90 py-0.5 select-none;

      .marker {
        @apply font-bold text-gray-500 text-xs inline-block pr-1 opacity-80;
      }

      &.is-todo {
        &:before {
          @apply border border-logseq-100/50 inline-block mr-1 rounded;

          content: " ";
          width: 14px;
          height: 14px;
        }
      }
    }

    > .subs {
      @apply relative pl-0.5 mt-0.5;

      &:before {
        @apply bg-logseq-50 absolute -top-0.5 -bottom-1 rounded opacity-20;

        content: " ";
        width: 4px;
        left: -14px;
      }
    }

    .ref {
      @apply bg-logseq-600/0 hover:bg-logseq-600/50 rounded
      transition-all duration-500 break-all;

      &:before {
        @apply text-gray-200 opacity-20 inline-block pr-0.5;
        content: "[[";
      }

      &:after {
        @apply text-gray-200 opacity-20 inline-block pl-0.5;
        content: "]]";
      }
    }
  }
}

.app-form-select {
  @apply px-2.5 py-3 bg-transparent  border-2 border-transparent rounded-xl
  appearance-none pl-10 font-semibold;

  &-wrap {
    @apply flex relative items-center active:opacity-70;

    > .icon {
      @apply absolute pl-3 text-xl;
    }

    > .arrow {
      @apply -ml-7 opacity-40;
    }
  }
}

.dockify-app-logo {
  position: relative;
  --top: -60px;
  --vertical: 12px;
  --expand: -4px;
  
  &::before, &::after {
    opacity: 0;
    position: absolute;
    left: var(--expand);
    right: var(--expand);
    text-align: center;
    font-size: 0.9rem;
    transition: all 300ms ease-out;
  }

  &::before {
    @apply bg-logseq-700;
    content: "";
    top: var(--top);
    height: calc(var(--vertical) * 3);
    z-index: 40;
    border-radius: 0.5rem;
  }

  &::after {
    content: "Logseq";
    top: calc(var(--top) - calc(var(--vertical) * 1.5));
    z-index: 50;
  }

  @screen sm {
    &:hover::before {
      opacity: 1;
    }
  
    &:hover::after {
      opacity: 0.75;
    }
  }
}

.app-tutorial-showcase {
  > .hd {
    letter-spacing: 1px;

    h1, h2 {
      line-height: 2.2rem;
    }

    h3, h4 {
      line-height: 2rem;
    }

    @screen sm {
      h1, h2 {
        line-height: 3.8rem;
      }

      h3, h4 {
        line-height: 2rem;
      }
    }
  }

  > .sub-hd {
    @apply -mt-8 px-52 pb-4;

    > li {
      @apply flex flex-col text-xl items-center flex-1 tracking-wide text-center whitespace-nowrap;

      &:before {
        content: " ";
        width: 100px;
        height: 100px;
        margin-bottom: 8px;
        background-image: url("./assets/features_sp.png");
        background-size: 760px;
        background-repeat: no-repeat;
        background-position-y: 2px;
        background-position-x: -26px;
      }

      &:nth-child(2):before {
        background-position-x: -250px;
      }

      &:nth-child(3):before {
        background-position-x: -452px;
      }

      &:nth-child(4):before {
        background-position-x: -636px;
      }

      sup {
        @apply text-xs absolute top-0 -right-[38px] text-logseq-100;
      }
    }
  }
}

.border-gradient-yellow-purple-red {
  background: linear-gradient(to right, #01171d, #01171d) content-box content-box, 
              linear-gradient(to bottom right, #fbbf24, #a855f7, #dc2626) border-box;
}

.border-gradient-red-purple-blue {
  background: linear-gradient(to right, #01171d, #01171d) content-box content-box, 
              linear-gradient(to bottom right, #e879f9, #a855f7, #2563eb) border-box;
}

.border-gradient-yellow-brown-yellow {
  background: linear-gradient(to right, #01171d, #01171d) content-box content-box, 
              linear-gradient(to bottom right, #facc15, #d6d3d1, #a16207) border-box;
}

.border-gradient-red-black-yellow {
  background: linear-gradient(to right, #01171d, #01171d) content-box content-box, 
              linear-gradient(to bottom right, #d946ef, #44403c, #facc15) border-box;
}

.border-gradient-green-black-cyan {
  background: linear-gradient(to right, #01171d, #01171d) content-box content-box, 
              linear-gradient(to bottom right, #22c55e, #78716c, #0891b2) border-box;
}

.app-tutorial-features-slide {
  @apply py-14 px-0;

  ul.tabs {
    li {
      @apply flex flex-col items-center bg-logseq-600/90 rounded-xl
      flex-1 p-0.5 justify-center select-none cursor-pointer
      active:opacity-80 transition-all;
      --border-opacity: 0.4;

      &:first-child {
        &.active {
          @apply border-gradient-yellow-purple-red;
        }

        &:hover {
          box-shadow: 0 2px 18px rgba(255, 66, 247, 0.3),
                      inset 0 0 7px rgba(255, 179, 66, 0.5);
        }
      }

      &:nth-child(2) {
        &.active {
          @apply border-gradient-red-purple-blue;
        }

        &:hover {
          box-shadow: 0 2px 18px rgba(110, 124, 251, 0.3),
          inset 0 0 7px rgba(251, 66, 255, 0.5);
          border-color: rgba(251, 66, 255, var(--border-opacity));
        }
      }

      &:nth-child(3) {
        &.active {
          @apply border-gradient-yellow-brown-yellow;
        }

        &:hover {
          box-shadow: 0 2px 18px rgba(171, 143, 118, 0.3),
          inset 0 0 7px rgba(255, 248, 238, 0.5);
          border-color: rgba(255, 248, 238, 0.4);
        }
      }

      &:nth-child(4) {
        &.active {
          @apply border-gradient-red-black-yellow
        }

        &:hover {
          box-shadow: 0 2px 18px rgba(255, 66, 247, 0.3),
          inset 0 0 7px rgba(255, 179, 66, 0.5);
          border-color: rgba(255, 179, 66, 0.4);
        }
      }

      &:nth-child(5) {
        &.active {
          @apply border-gradient-green-black-cyan;
        }

        &:hover {
          box-shadow: 0px 2px 18px rgba(53, 113, 99, 0.3),
          inset 0px 0px 7px rgba(92, 209, 186, 0.5);
          border-color: rgba(92, 209, 186, 0.4);
        }
      }

      span {
        font-size: 38px;
      }

      strong {
        @apply text-[20px] pt-1 font-normal opacity-70 text-center tracking-wide;
      }

      &.active {
        > strong {
          @apply opacity-100 font-semibold;
        }
      }
    }
  }
}

.app-tutorial-features-panel {
  @apply mt-8 rounded-2xl border-logseq-500 overflow-hidden
  sm:mb-6 sm:min-h-[300px];

  > .hd {
    > strong {
      @apply flex space-x-2 px-3 py-2;

      > i {
        @apply flex-1 bg-logseq-100/10 rounded-md h-[6px] overflow-hidden;
      }
    }

    > h1 {
      @apply mt-2 sm:mt-4 my-0 sm:my-2;
    }

    @apply flex flex-col px-2 pt-2 pb-3 sm:py-4;
  }

  > .bd {
    .wrap {
      @apply px-1 flex items-center pt-2
      sm:pt-0 sm:pt-8 sm:pb-4 sm:px-0;

      > .a {
        @apply hidden sm:block;
      }

      &.is-students {
        @apply sm:flex-col;

        > .b {
          @apply sm:flex sm:py-10 sm:-translate-y-12 relative z-10 bg-transparent;
        }
      }

      &:not(.is-students) {
        @apply sm:flex-row-reverse sm:pt-16;

        > .a {
          @apply mr-3;
        }

        > .b {
          @apply sm:grid gap-x-4 grid-rows-2 grid-cols-2 sm:mr-12 sm:my-6 gap-y-2;

          .desc-card {
            @apply pl-0;
          }
        }
      }
    }

    .desc-card {
      @apply mb-4 flex-1
      sm:mb-0 sm:pl-10;

      > h2 {
        a {
          @apply text-logseq-100;
        }
      }

      &:first-child {
        @apply pl-0;
      }
    }
  }

  .app-window-holder {
    height: 270px;
    width: 320px;
    position: relative;

    background-image: url("./assets/app-window-placeholder-bg-no-logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: 60px;

    &:not(.without-logo) {
      &:after {
        content: " ";
        width: 80px;
        height: 80px;
        position: absolute;
        top: 20px;
        right: -10px;

        background-image: url("../../../assets/logo-with-shadow.png");
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    &.without-logo {
      width: 200px;
      height: 110px;
      background-position-y: 8px;
      background-position-x: center;
    }
  }
}

.app-tutorial-features-select {
  @apply px-4;

  .app-form-select {
    @apply border-gradient-br-red-purple-blue-logseq-900 outline-0;
  }

  &.index-of-0 .app-form-select {
    @apply border-gradient-br-yellow-purple-red-logseq-900;
  }

  &.index-of-1 .app-form-select {
    @apply border-gradient-br-red-purple-blue-logseq-900;
  }

  &.index-of-2 .app-form-select {
    @apply border-gradient-br-yellow-brown-yellow-logseq-900;
  }

  &.index-of-3 .app-form-select {
    @apply border-gradient-br-red-black-yellow-logseq-900;
  }

  &.index-of-4 .app-form-select {
    @apply border-gradient-br-green-black-cyan-logseq-900;
  }
}

.app-tutorial-tips {
  @apply mx-4 mt-16 mb-4 sm:mx-16 sm:my-8;

  > .hd {
    h1, h2 {
      @apply text-4xl;

      line-height: 1.2em;
    }

    h3 {
      @apply opacity-60;
    }

    h2 {
      @apply font-bold mb-6 inline sm:block;
    }

    h3, h4 {
      font-size: 26px;
      line-height: 1.4em;
    }

    .app-logo {
      display: inline-flex;
      border-radius: 10px;
      padding: 8px;
    }

    @screen sm {
      h1, h2 {
        font-size: 60px;
        line-height: 1.2em;
      }

      h3, h4 {
        font-size: 38px;
        line-height: 1.2em;
      }
    }
  }

  > .bd {
    @apply mt-8 mb-10 sm:my-16 sm:-mx-10 relative;

    .bd-wrapper {
      place-self: center right;
      flex-basis: 486px;
      align-self: stretch;
      display: flex;
      flex-direction: column;
      /* flex-wrap: wrap; */
      justify-content: center;
      align-items: center;
      max-width: 486px;
    }

    .bd-slides {
      @apply w-full flex-col items-center justify-center px-0
      border bg-logseq-500 border-gray-400/50 rounded-3xl relative h-[210px]
      sm:flex sm:h-[256px]  sm:w-0 sm:px-6;

      /* flex-basis: 486px; */
      width: 100%;

      .items {
        @apply w-full;

        height: 240px;

        > .item {
          @apply flex flex-col w-full py-4 px-6;

          > h1 {
            @apply flex items-center py-2;

            button {
              @apply py-2 px-5 font-bold opacity-80 text-gray-200 uppercase;
            }
          }

          .progress {
            @apply flex space-x-2 mt-4 mb-2 cursor-pointer;

            > i {
              @apply flex-1 h-[4px] bg-gray-500/30 rounded-md overflow-hidden;

              > small {
                @apply flex h-[4px] bg-logseq-200/80 transition-all;
              }
            }
          }
        }
      }
    }

    .bd-actions {
      @apply justify-between px-2 py-2 mt-3 sm:w-[482px];

      .prev, .next {
        @apply opacity-80 cursor-pointer select-none hover:opacity-90 active:opacity-90;
      }
    }

    > .bd-info {
      @apply border flex-1 border-logseq-400 rounded-2xl
      relative overflow-hidden mt-6
      sm:ml-4 sm:mt-0;

      place-self: center left;
    }
  }

  > .ft {

  }
}

.app-landing-footer {
  &-desc {
    @apply pt-16 px-4 -translate-y-6
    sm:pt-0 sm:px-0;

    > .cards {
      @apply mt-6 sm:flex flex-wrap sm:mx-[5.5rem] sm:my-12 translate-y-5;

      > .item {
        @apply sm:basis-1/4;

        > .inner {
          @apply bg-logseq-600/90 p-4 rounded-lg;
        }
      }
    }

    > .actions {
      @apply flex flex-col py-6 rounded-3xl
      mt-4 px-4 bg-logseq-500 sm:items-center
      sm:mx-10 sm:mt-24 sm:py-16 sm:px-0 sm:bg-transparent;
    }
  }

  &-navs {
    @apply pb-8 w-full sm:flex sm:justify-between sm:pt-6 sm:pb-14 pl-4 pr-4 sm:pr-12 items-center;

    > .links {
      @apply pt-6 px-1;
    }
  }
}

.app-page {
  @apply flex flex-col items-center;

  .page-inner-full-wrap {
    @apply flex items-center justify-center flex-col;

    &.a {
      @apply bg-[#001419] relative;

      min-height: 220px;
      background-image: url("./assets/full-bg-a.png");
      background-size: contain;
      background-repeat: no-repeat;
      padding-top: 160px;

      .page-inner {
        z-index: 2;
        /* margin-top: -768px; */

        @screen lg {
          max-width: 1440px;
        }
      }

      .text-slogan {
        @apply px-4 pt-8 pb-32;
      }

      &:before {
        @apply absolute bottom-0 left-0 w-full;

        content: " ";
        background-image: url("./assets/full-bg-c-gradient.png");
        background-repeat: repeat-x;
        z-index: 1;
        background-position: bottom;
        height: 655px;
      }

      @screen sm {
        background-position-y: -20px;

        .page-inner {
          margin-top: unset;
        }

        &:before {
          height: 525px;
        }

        .text-slogan {
          @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -mt-16
          tracking-wide p-0;

          > h1 {
            line-height: 1.2em;
          }
        }
      }
    }

    &.b {
      @apply mt-[180px] sm:mt-0;

      background-image: url("./assets/light_spots_bg.png");
    }
  }
}

.border-gradient-blue-white-green {
  background: linear-gradient(to right, #01171d, #01171d) content-box content-box, 
              linear-gradient(#60a5fa, #fff, #bbf7d0) border-box;
}

.border-gradient-gray-white-gray {
  background: linear-gradient(to right, #01171d, #01171d) content-box content-box, 
              linear-gradient(#a8a29e, #fff, #57534e) border-box;
}

.border-gradient-brown-white-blue {
  background: linear-gradient(to right, #01171d, #01171d) content-box content-box, 
              linear-gradient(#fde047, #fff, #60a5fa) border-box;
}

.border-gradient-gray-white-blue {
  background: linear-gradient(to right, #01171d, #01171d) content-box content-box, 
              linear-gradient(#78716c, #fff, #713f12) border-box;
}

.app-daily-showcase {
  @apply -mt-2 relative mb-10 px-4 sm:px-0;

  z-index: 3;

  > .tabs {

    > .it {
      @apply items-center p-0.5
      bg-logseq-700 rounded-xl cursor-pointer select-none
      transition-all active:opacity-80;

      &:hover {
        box-shadow: 0 2px 18px rgba(23, 23, 23, 0.3),
        inset 0 0 7px rgba(237, 237, 236, 0.5);
      }

      &:first-child {
        &.active {
          @apply border-gradient-blue-white-green;
        }
      }

      &:nth-child(2) {
        &.active {
          @apply border-gradient-gray-white-gray;
        }
      }

      &:nth-child(3) {
        &.active {
          @apply border-gradient-brown-white-blue;
        }
      }

      &:nth-child(4) {
        &.active {
          @apply border-gradient-gray-white-blue;
        }
      }

      &.active strong {
        @apply opacity-80 font-semibold;
      }
    }
  }

  > .panels {
    @apply sm:px-5;

    .desc {
      @apply flex text-xl pt-6 px-1 pb-1 tracking-wide opacity-80
      sm:text-4xl sm:text-center sm:justify-center sm:pt-12
      sm:px-0 sm:leading-10 sm:h-[140px];

      > span {
        @apply opacity-60;
      }
    }

    .card {
      @apply pt-6 pb-10 sm:pb-20 sm:pt-10;

      > .l {
        @apply w-full flex flex-1 border rounded-xl border-logseq-500/50 overflow-hidden;
      }

      > .r {
        @apply flex w-full mt-6 sm:mt-0 sm:pl-6 sm:w-[500px];

        > .inner {
          @apply border p-6 w-full rounded-xl border-gray-700/80 bg-logseq-800/10
          flex flex-col justify-between;

          > .t {
          }

          > .b {
            @apply text-xl sm:text-[26px];

            -webkit-font-smoothing: antialiased;

            > .fd {
              @apply leading-7 pt-6 pb-2 sm:leading-[35px] sm:pt-0 sm:pb-[20px];

              white-space: break-spaces;

              span {
                @apply opacity-60;
              }
            }

            > .ft {
              @apply flex justify-between pt-2 translate-y-1.5
              text-base sm:text-[18px] sm:pb-2 sm:pt-2 sm:translate-y-0;
            }
          }
        }
      }
    }

    .progress {
      @apply cursor-pointer hover:opacity-80 active:opacity-100;
    }
  }

  > .selects {
    .icon img {
      width: 25px;
      height: 25px;
    }

    .app-form-select {
      @apply outline-0;
    }

    &.index-of-0 .app-form-select {
      @apply border-gradient-b-blue-white-green-logseq-900;
    }

    &.index-of-1 .app-form-select {
      @apply border-gradient-b-gray-white-gray-logseq-900;
    }

    &.index-of-2 .app-form-select {
      @apply border-gradient-b-brown-white-blue-logseq-900;
    }

    &.index-of-3 .app-form-select {
      @apply border-gradient-b-gray-white-blue-logseq-900;
    }
  }
}

.app-features-boards {
  @apply pt-8 sm:block;

  .board-item {
    @apply px-4 mb-6
    sm:min-h-[300px] sm:mx-24 sm:px-16 sm:mb-10;

    position: relative;
    box-sizing: border-box;
    background: url("./assets/gradient-bg-demo.png") repeat-x;
    background-clip: padding-box;
    background-size: cover;
    border: solid 1px transparent;
    border-radius: 1em;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -1px;
      border-radius: inherit;
      background: linear-gradient(to bottom, #4d4d4d, #0e2c2f);
    }

    > .inner {
      @apply flex;

      > .l {
        @apply flex-col pt-4 pb-5
        sm:pt-10 sm:pb-12;
      }

      > .img-wrap {
        background-image: url("./assets/sync-indicator-demo.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: bottom;
        background-position-x: 20px;
      }

      > .info-wrap {
        strong {
          @apply w-[72px] h-[72px] overflow-hidden flex items-center justify-center
          bg-gray-600/40 rounded-[50%];
        }

        h1 {
          @apply font-semibold text-[21px] pt-3 sm:text-[24px] sm:pt-4 pb-2 sm:tracking-wide;

          sup {
            @apply text-xs text-logseq-100;
          }
        }

        h2 {
          @apply text-[25px] tracking-wide leading-[1.2em]
          pt-1 pb-2 sm:text-[38px]  sm:py-2;
        }

        h3 {
          @apply text-[20px] pt-3 tracking-wide;
        }
      }

      &.whiteboard {
        @apply pt-4 pb-5
        sm:pt-10 sm:pb-12;

        > .img-wrap {
          @apply -mb-12 -ml-16;

          background-image: url("./assets/whiteboard-demo.png");
          background-position-x: left;
          background-position-y: bottom;
          border-radius: 22px;
        }

        > .r {
          @apply sm:pl-16;
        }
      }
    }
  }

  &.dl-page {
    @apply flex
    sm:space-x-7 sm:px-32;

    > .board-item-wrap {
      @apply flex-1;
    }

    .board-item {
      @apply sm:mx-0 sm:px-8;

      &.file-sync, &.rtc-collaboration {
        @apply sm:mb-[30px];

        .info-wrap h3 {
          background-image: url("./assets/filesync-abstraction.png");
          background-size: 130%;
          background-repeat: no-repeat;
          height: 60px;
          margin-top: 8px;
          margin-bottom: -8px;

          @screen sm {
            background-size: contain;
            height: 60px;
            margin-top: 15px;
            margin-bottom: 0;
          }
        }
      }

      &.rtc-collaboration {
        .info-wrap h3 {
          background-image: url("./assets/rtc-abstraction.png");
          height: 74px;
          background-size: 95%;
          position: relative;
          left: -20px;
          padding-top: 4px;

          @screen sm {
            height: 86px;
            position: relative;
            left: -16px;
            background-size: contain;
          }
        }
      }

      &.whiteboard {
        @apply flex;

        > .inner {
          @apply pt-4 flex flex-1 pb-20 -mx-4 -mt-4
          sm:pt-10 sm:-mx-8 sm:-mb-2 sm:px-8;

          border-radius: 1em;
          overflow: hidden;
          background-image: url("./assets/whiteboard-demo-small.png");
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: bottom;

          @screen sm {
            background-size: contain;
            background-image: url("./assets/whiteboard_canvas_fadeout.png");
          }

          > .r {
            @apply px-4 py-4 sm:px-0 sm:py-0;
          }
        }
      }
    }
  }
}
