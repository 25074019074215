@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-gray-100 bg-logseq-800;

  &.is-nav-open {
    overflow: hidden;
  }
}

.app-container {
  @apply w-full flex-1 overflow-hidden;
}

.ani-slide-in-from-bottom {
  /* @apply animate-in fade-in-0 duration-1000 slide-in-from-bottom-14; */
  @apply animate-in fade-in-0 slide-in-from-bottom-14;
  animation-duration: 2s;

  visibility: visible !important;
}

.ani-slide-in-from-top {
  @apply animate-in fade-in-0 duration-1000 slide-in-from-top-14;

  visibility: visible !important;
}

.ani-slide-in-from-left {
  @apply animate-in fade-in-0 duration-1000 slide-in-from-left-14;

  visibility: visible !important;
}

.ani-slide-in-from-right {
  @apply animate-in fade-in-0 duration-1000 slide-in-from-right-14;

  visibility: visible !important;
}

.ani-zoom-in {
  @apply animate-in zoom-in duration-1000 fade-in-0;

  visibility: visible !important;
}

.ani-fade-in {
  @apply animate-in fade-in duration-1000;

  visibility: visible !important;
}

.glass-card {
  @apply bg-gradient-to-b from-white/20 to-logseq-800/5 rounded-md;

  visibility: hidden;

  &-inner {
    @apply bg-logseq-700/50 rounded-md p-3 w-full;

    margin: .5px;
    padding-top: 15px;
  }
}

.glass-btn {
  @apply backdrop-blur-sm bg-logseq-500/20 px-2 py-2 rounded-md border border-gray-500/30
  select-none active:opacity-70 cursor-pointer hover:border-gray-500/50 flex justify-center items-center;
}

.app-logo-link {
  display: inline-block;
  width: 32px;
  height: 32px;
  position: relative;
  right: -5px;
  top: 1px;

  border-radius: 8px;
  box-shadow: 0 4px 32px 0 rgba(133, 200, 200, 0.3);
}

.app-headbar {
  @apply w-full px-6 fixed bg-logseq-800/70 z-50;
  backdrop-filter: blur(70px);

  .links-group {
    @apply flex flex-1 space-x-8 whitespace-nowrap;

    a {
      @apply text-logseq-100 sm:text-[#A4B5B6] text-base sm:text-lg hover:text-gray-300;

      &.app-link-active {
        @apply text-gray-300 font-semibold sm:opacity-100;

        > * {
          @apply opacity-100;
        }
      }
    }
  }

  .right-group {
    &-inner {
      @apply invisible absolute left-0 top-[54px] w-full
      flex-col space-x-0 items-center px-2 pb-6 opacity-0 transition-opacity duration-300
      sm:flex sm:flex-row sm:top-0 sm:sticky sm:opacity-100 sm:visible
      sm:px-0 sm:space-x-6 sm:pb-0 sm:translate-y-0;

      z-index: -1;

      @screen sm {
        z-index: 1;
      }
    }

    &:before {
      @apply sm:hidden z-40;

      display: none;
      content: " ";
      height: 100vh;
      width: 100vw;
      background: rgba(7, 7, 7, 0.43);
      position: fixed;
      left: 0;
      top: 90px;
    }

    &.is-active {
      .right-group-inner {
        @apply visible flex opacity-100 z-40 bg-logseq-800 sm:bg-logseq-800/0;
      }

      &:before {
        @apply block sm:hidden;
        /* display: block; */
      }
    }
  }
}

.app-link-active {
}

.app-toaster {
  @apply !bg-logseq-500 !text-gray-100;
}

.app-logo {
  @apply flex items-center justify-center
  bg-logseq-700 overflow-hidden border-logseq-800
  shadow-2xl shadow-logseq-300 p-3 rounded-3xl;

  img {
    @apply translate-y-0.5;
  }
}

.app-logo-embossed {
  background: linear-gradient(to right, #0E2A35, #0E2A35) content-box content-box,
  linear-gradient(to bottom, rgba(133, 200, 200, 0.1), rgba(133, 200, 200, 0), rgba(0, 0, 0, 0.2)) border-box,
  #0E2A35 border-box;
  padding: 2px;
  display: grid;
  place-items: center;
  border-radius: 16px;
  filter: drop-shadow(0px 4px 16px rgba(133, 200, 200, 0.2)) drop-shadow(0px 8px 6px rgba(133, 200, 200, 0.1));
  transition: filter 300ms ease-out;

}

.app-terms {
  padding-top: 80px;
}

.dockify-app-logo:hover .app-logo-embossed {
  cursor: pointer;
  filter: drop-shadow(0px 4px 16px rgba(133, 200, 200, 0.3)) drop-shadow(0px 8px 6px rgba(133, 200, 200, 0.2));
}

.app-privacy-banner {
  @apply fixed left-0 bottom-0 right-0 p-6 flex justify-center
  z-50 bg-logseq-300/10 backdrop-blur-5xl;

  .r {
    @apply flex space-x-4 py-3
    justify-center sm:py-0;

    > button {
      @apply justify-center;
    }
  }
}

.ui-modal {
  @apply fixed w-screen h-screen flex justify-center
  top-0 left-0 z-50 bg-black/50 overflow-auto py-10;

  &.hidden {
    @apply !hidden;
  }

  &-inner {
    @apply relative m-auto;
  }

  &-content {
    @apply relative bg-logseq-700 border border-logseq-600 rounded-xl
    w-[90vw] max-w-[840px] min-h-[600px] p-6;
  }

  &-close {
    @apply absolute top-6 right-6 opacity-60 hover:opacity-80
    cursor-pointer active:opacity-50 select-none;
  }

  &.as-confirm {
    .ui-modal-content {
      @apply min-h-[180px] max-w-[700px];
    }

    .ui-modal-inner {
      @apply top-[-50px] animate-in zoom-in duration-300;
    }
  }

  &-container-locked {
    overflow-y: hidden;
  }
}

.ui-dropdown {
  @apply relative;

  .sub-items {
    transform-origin: top;
    transform: scaleY(0);
    min-width: 270px;
    display: flex;

    &-inner {
      @apply border bg-logseq-600 rounded-md overflow-hidden
      border-logseq-400 p-1;

      > a {
        @apply select-none cursor-pointer rounded-md
        pl-3 pr-2 py-1.5 hover:bg-logseq-400/80 transition-all;
      }
    }
  }

  &.is-active {
    .sub-items {
      transform: scaleY(1);
    }
  }
}
